import React, { useMemo } from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay, FreeMode } from "swiper";
import { StaticImage } from "gatsby-plugin-image"

import 'swiper/css';
import "swiper/css/navigation";

import Contact from "../../components/sections/contact";

const KanadaYa = () => {

    const imageProps = {
        layout: "fullWidth",
        quality: 45,
        formats: ["auto", "webp", "avif"],
        placeholder: "blurred",
        className: "h-full"
    }

    // const [modal, setModal] = useState(0)
    // const [caption, setCaption] = useState("")

    const InteriorImage = ({index, item}) => {
        switch(index){
            case 1:
                return <StaticImage src={`../../images/brand/chilis/chilis-1.jpeg`} alt={item} {...imageProps}/>
            case 2:
                return <StaticImage src={`../../images/brand/chilis/chilis-2.jpeg`} alt={item} {...imageProps}/>
            default:
                return <StaticImage src={`../../images/brand/chilis/chilis-3.jpeg`} alt={item} {...imageProps}/>
        }
    }

    const sliderArray = useMemo(()=> [
        "Chilis Central Gurney",
        "Chilis Central Gurney",
        "Chilis Central Gurney",
    ], [])

    return(
        <Layout>
        <Seo title="Central@Gurney | Chili's" />

        {
            typeof window !== 'undefined' && 
            <div>
                <div className="container mx-auto px-5">
                    <div className="pt-24 pb-6 flex flex-col items-center justify-center">
                        {/* <div className="w-20 mr-5">
                            <StaticImage src="../../images/brand/brand-2.png" className="transition-basic hover:opacity-50" {...imageProps}></StaticImage>
                        </div> */}
                        <h2 className="text-4xl font-semibold mb-2">Chili's</h2>
                        <p className="font-light text-gray-600">Daily | 8am – 10pm</p>
                        <p className="font-light text-gray-600">For enquiries: 04-2170685</p> 
                    </div>
                </div>
                <Swiper
                    className="swiper-tenant"
                    modules={[Navigation, Autoplay, FreeMode]}
                    navigation={true}
                    spaceBetween={8}
                    slidesPerView={1}
                    // centeredSlides
                    breakpoints={{
                        768: {
                            slidesPerView: 3,
                            spaceBetween: 16,
                            // centeredSlides: false,
                            initialSlide: 0
                        },
                    }}
                >
                    {sliderArray.map((item, i) => (
                        <SwiperSlide key={"tenant"+item}>
                            <div className="aspect-video bg-gray-200 relative md:my-5">
                                <InteriorImage index={i+1} item={item}/>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
                <div className="container mx-auto px-5">
                    <div className="pt-10 pb-20 xl:px-16 w-full mx-auto md:w-7/12">
                        <p className="font-light leading-relaxed">          
                        Originating in 1975 in Dallas, Texas, Chili's has expanded to over 1600 restaurants worldwide, renowned for its best-in-class burgers, beef ribs, and fajitas, Chili's offers an authentic American meal with Texas roots and Fresh Mex style. Every dish, crafted in their kitchen, promises big flavour and satisfaction. Debuting at Central Gurney with a fresh cafe concept, Chili's caters to all-day cravings, offering morning coffees to evening mocktails, guaranteeing an exceptional experience for hotel guests, tourists, and locals. Whether it's a casual hangout, a romantic evening, or a family gathering, Chili's ensures a dining experience brimming with joy, making it the perfect destination for memorable moments and delightful meals. 
                        </p>
                        {/* <div className="w-full mx-auto relative mt-12">
                            <StaticImage src={`../../images/promotion/promotion-5-kanada.jpg`} {...imageProps}/>
                        </div> */}
                    </div>
                </div>
                <Contact/>
            </div>
        }

        </Layout>
    )
}

export default KanadaYa
